<template>
  <div class="infinite-scroll">
    <RecycleScroller
      v-if="!!list.length"
      ref="scroller"
      v-slot="{ item }"
      :items="list"
      :emit-update="true"
      :item-size="itemHeight"
      :buffer="200"
      :style="`max-height: ${height}; height: ${height}`"
      key-field="reference"
      @update="onUpdate"
    >
      <slot v-if="item.reference !== 'LOADING'" name="item" :item="item" />
      <slot v-else name="loading">
        <q-item>
          <q-item-section>
            <div class="row">
              <div class="col-sm-10 offset-sm-1 text-caption text-center">
                {{ $t('theres_more', { num: total - getter.length }) }}
                <q-spinner :size="28" style="margin: auto" />
              </div>
            </div>
          </q-item-section>
        </q-item>
      </slot>
    </RecycleScroller>
    <div v-else-if="total === 0" :style="`height: ${height}`" class="empty">
      <div class="message">
        <h4>{{ $t('nothing_here') }}</h4>
        <p>{{ $t('no_results_check_filters') }}</p>
      </div>
    </div>
    <div v-else class="loading">
      <q-spinner :size="48" style="margin: auto" />
    </div>
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
export default {
  components: {
    RecycleScroller
  },
  props: {
    load: Function,
    itemHeight: Number,
    height: String,
    getter: Array,
    width: String
  },
  data () {
    return {
      loadingLimit: 20,
      loadingOffset: 1,
      total: null
    }
  },
  computed: {
    list () {
      if (this.total !== null) {
        const items = [...this.getter]
        const moreToCome = this.total - items.length

        if (items.length && moreToCome) {
          items.push({ reference: 'LOADING' })
        }

        return items
      } else {
        return []
      }
    }
  },
  async created () {
    this.requestData()
  },
  methods: {
    reset () {
      this.loadingOffset = 1
      if (this.$refs.scroller) {
        this.$refs.scroller.$el.scrollTop = 0
      }
      this.requestData()
    },
    onUpdate (startIndex, endIndex) {
      if (endIndex > this.loadingOffset * this.loadingLimit) {
        this.loadingOffset++
        this.requestData()
      }
    },
    async requestData () {
      const pagination = await this.load(this.loadingOffset, this.loadingLimit)
      if (this.total !== pagination.total) {
        this.total = pagination.total
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.scroller {
  height: 100%;
}

.empty
  display grid

.message
  margin auto
  text-align center

.message p
  margin-top 16px
  line-height 1.5

.loading
  display grid
  text-align center
  height 128px
  width 100%
</style>
